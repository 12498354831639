import { filters } from 'fabric'

import { IImageSetting, ImageColor, ImageFilter } from '#modules/workspace/types/element'

interface FilterSetting<T> {
  colorKey: T
  filterInstance: () => filters.BaseFilter<string>
  valueKey?: string
  fixedValue?: number[]
  initial?: boolean
}

export const getBooleanFilterSettings = (): Array<FilterSetting<keyof ImageFilter>> => {
  return [
    { colorKey: 'grayscale', filterInstance: () => new filters.Grayscale() },
    { colorKey: 'invert', filterInstance: () => new filters.Invert() },
    { colorKey: 'sepia', filterInstance: () => new filters.Sepia() },
    { colorKey: 'brownie', filterInstance: () => new filters.Brownie() },
    { colorKey: 'vintage', filterInstance: () => new filters.Vintage() },
    { colorKey: 'sharpen', filterInstance: () => new filters.Convolute(), valueKey: 'matrix', fixedValue: [0, -1, 0, -1, 5, -1, 0, -1, 0] },
    { colorKey: 'emboss', filterInstance: () => new filters.Convolute(), valueKey: 'matrix', fixedValue: [1, 1, 1, 1, 0.7, -1, -1, -1, -1] },
    { colorKey: 'technicolor', filterInstance: () => new filters.Technicolor() },
    { colorKey: 'polaroid', filterInstance: () => new filters.Polaroid() },
    { colorKey: 'kodachrome', filterInstance: () => new filters.Kodachrome() },
    { colorKey: 'blackwhite', filterInstance: () => new filters.BlackWhite() }
  ]
}

export const getValueFilterSettings = (): Array<FilterSetting<keyof ImageColor>> => {
  return [
    { colorKey: 'brightness', filterInstance: () => new filters.Brightness() },
    { colorKey: 'contrast', filterInstance: () => new filters.Contrast() },
    { colorKey: 'saturation', filterInstance: () => new filters.Saturation() },
    { colorKey: 'vibrance', filterInstance: () => new filters.Vibrance() },
    { colorKey: 'noise', filterInstance: () => new filters.Noise() },
    { colorKey: 'pixelate', filterInstance: () => new filters.Pixelate(), valueKey: 'blocksize' },
    { colorKey: 'blur', filterInstance: () => new filters.Blur() },
    { colorKey: 'hue', filterInstance: () => new filters.HueRotation(), valueKey: 'rotation' }
  ]
}

export const getAllFilterSettings = () => [
  // value filter
  ...getBooleanFilterSettings(),
  // boolean filter
  ...getValueFilterSettings()
]

export function getFlipScale(flip: IImageSetting['flip']) {
  const isHorizontal = flip === 'horizontal' || flip === 'both'
  const isVertical = flip === 'vertical' || flip === 'both'
  return `scaleX(${isHorizontal ? -1 : 1}) scaleY(${isVertical ? -1 : 1})`
}

export function calculateBoundingBox(width: number, height: number, angleInDegrees: number): { boundingWidth: number; boundingHeight: number } {
  // 将角度转换为弧度
  const angleInRadians = angleInDegrees * (Math.PI / 180)

  // 计算正弦和余弦
  const cosTheta = Math.cos(angleInRadians)
  const sinTheta = Math.sin(angleInRadians)

  // 计算外接矩形的宽度和高度
  const boundingWidth = Math.abs(width * cosTheta) + Math.abs(height * sinTheta)
  const boundingHeight = Math.abs(width * sinTheta) + Math.abs(height * cosTheta)

  return {
    boundingWidth,
    boundingHeight
  }
}
